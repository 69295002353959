var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Form } from 'react-bootstrap';
export default function InputRadio(props) {
    var settings = props.settings;
    return (_jsxs("fieldset", __assign({ className: "form-group" }, { children: [_jsx("label", __assign({ className: "form-label" }, { children: settings.label })), props.labels.map(function (label, key) { return (_jsx(_Fragment, { children: _jsx(Form.Check, { type: "radio", id: "".concat(settings.id, "-option-").concat(key), checked: key == props.value, name: settings.id, value: key, disabled: settings.disabled, onChange: props.onChange, label: label, autoComplete: "off" }) })); })] })));
}
