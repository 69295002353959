var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import useToggle from '../../../hooks/useToggle';
import { useToasts } from 'react-toast-notifications';
import Input from '../../../components/Input';
import InputRadio from '../../../components/Input/Radio';
import Icon from '../../../components/Icon';
import icon_regenerate from '../../../assets/images/icons/rotate.svg';
import icon_show from '../../../assets/images/icons/eye.svg';
import icon_hide from '../../../assets/images/icons/eye-slash.svg';
import icon_gear from '../../../assets/images/icons/gear.svg';
import icon_save from '../../../assets/images/icons/floppy-disk.svg';
export default function CreateUser() {
    var addToast = useToasts().addToast;
    var _a = useState(false), formLoading = _a[0], setFormLoading = _a[1];
    var _b = useState({
        name: "",
        email: "",
        role: 0,
        password: ""
    }), formValue = _b[0], setFormValue = _b[1];
    var _c = useToggle(), showPassword = _c[0], setShowPassword = _c[1];
    var _formatValues = function (value, key) {
        if (key == "role") {
            return parseInt(value);
        }
        return value;
    };
    var genereateRandomPassword = function () {
        var password = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        // get a random number between 0 and the length of the possible string
        var min = 8, max = 16;
        var length = Math.floor(Math.random() * (max - min + 1) + min);
        for (var i = 0; i <= length; i++) {
            password += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return password;
    };
    var handleChange = function (event) {
        var _a;
        var value = event.target.value;
        var newValue = _formatValues(value, event.target.name);
        setFormValue(__assign(__assign({}, formValue), (_a = {}, _a[event.target.name] = newValue, _a)));
    };
    var handleSubmit = function () {
        setFormLoading(true);
        window.axios({
            method: 'POST',
            url: '/api/user',
            data: formValue
        })
            .then(function (response) {
            setFormLoading(false);
            addToast(response === null || response === void 0 ? void 0 : response.data, { appearance: 'success' });
        })
            .catch(function (err) {
            var _a;
            setFormLoading(false);
            if (window.axios.isAxiosError(err)) {
                var errors = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors;
                errors && Object.entries(errors).map(function (message, index) { return (addToast(message[1], { appearance: 'error', autoDismissTimeout: 10000 })); });
            }
        });
    };
    return (_jsx("div", __assign({ id: "page-user-create" }, { children: _jsx("div", __assign({ className: "card card-user shadow" }, { children: _jsxs("form", __assign({ className: "form-user" }, { children: [_jsxs("div", __assign({ className: "grid-2" }, { children: [_jsxs("div", __assign({ className: "grid-1" }, { children: [_jsx(Input, { settings: {
                                            id: "name",
                                            label: "Namn",
                                            placeholder: "Namn",
                                        }, value: formValue === null || formValue === void 0 ? void 0 : formValue.name, onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                            id: "email",
                                            label: "Email",
                                            placeholder: "Mejl",
                                        }, value: formValue === null || formValue === void 0 ? void 0 : formValue.email, onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                            id: "password",
                                            label: "Lösenord",
                                            placeholder: "Lösenord...",
                                            type: showPassword ? "text" : "password"
                                        }, value: formValue === null || formValue === void 0 ? void 0 : formValue.password, onChange: function (e) { return handleChange(e); } }), _jsxs("div", __assign({ className: "flex-row" }, { children: [_jsxs("button", __assign({ className: "btn btn-info", onClick: function (e) {
                                                    e.preventDefault();
                                                    var randomPassword = genereateRandomPassword();
                                                    setFormValue(__assign(__assign({}, formValue), { password: randomPassword }));
                                                } }, { children: [_jsx(Icon, { src: icon_regenerate }), _jsx("span", { children: "Generera l\u00F6senord" })] })), _jsxs("button", __assign({ className: "btn btn-danger", onClick: function (e) {
                                                    e.preventDefault();
                                                    setShowPassword(false);
                                                } }, { children: [showPassword ? _jsx(Icon, { src: icon_hide }) : _jsx(Icon, { src: icon_show }), _jsxs("span", { children: [showPassword ? 'Dölj' : 'Visa', " L\u00F6senord"] })] }))] }))] })), _jsx("div", __assign({ className: "grid-1 grid-center" }, { children: _jsx("div", __assign({ className: "form-group" }, { children: _jsx(InputRadio, { settings: {
                                            label: "Rättigheter",
                                            id: "role"
                                        }, labels: ["Användare", "Administratör"], value: formValue.role, onChange: function (e) { return handleChange(e); } }) })) }))] })), _jsx("button", __assign({ disabled: formLoading, type: "submit", className: "btn btn-primary mx-auto", onClick: function (e) {
                            e.preventDefault();
                            handleSubmit();
                        } }, { children: formLoading ? (_jsxs(_Fragment, { children: [_jsx(Icon, { src: icon_gear, classes: "animate-spin" }), "Laddar..."] })) : (_jsxs(_Fragment, { children: [_jsx(Icon, { src: icon_save }), _jsx("span", { children: "Skapa anv\u00E4ndare" })] })) }))] })) })) })));
}
