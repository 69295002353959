var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export default function Input(props) {
    var _a, _b, _c;
    var settings = props.settings;
    var CheckLabel = function () {
        if (settings.label) {
            return (_jsx("label", __assign({ className: "form-label" }, { children: settings.label })));
        }
        return null;
    };
    var checkReadOnly = function () {
        if (settings.readOnly) {
            return 'form-control-plaintext';
        }
        else {
            return 'form-control';
        }
    };
    return (_jsxs("div", __assign({ className: "form-group ".concat(props.classes) }, { children: [_jsx(CheckLabel, {}), _jsxs("div", __assign({ className: "input-group" }, { children: [settings.prefix && (_jsx("span", __assign({ className: "input-group-text" }, { children: settings.prefix }))), _jsx("input", { className: [checkReadOnly(), settings.classes].join(" "), id: settings.id, name: settings.id, type: (_a = settings.type) !== null && _a !== void 0 ? _a : "text", placeholder: settings.placeholder, min: settings.min, max: settings.max, step: settings.step, disabled: settings.disabled, readOnly: settings.readOnly, value: props.value, onChange: props.onChange, onFocus: (_b = props.onFocus) !== null && _b !== void 0 ? _b : undefined, onBlur: (_c = props.onBlur) !== null && _c !== void 0 ? _c : undefined, autoComplete: "off" }), settings.suffix && (_jsx("span", __assign({ className: "input-group-text" }, { children: settings.suffix })))] }))] })));
}
