import { jsx as _jsx } from "react/jsx-runtime";
// Page Imports
import Home from './pages/Home';
import Users from './pages/Users';
import CreateUser from './pages/Users/Create';
import EditUser from './pages/Users/Edit';
import Forklifts from './pages/Forklifts';
import SettingsPage from './pages/Settings';
import LogsPage from './pages/Logs';
import EditForklift from './pages/Forklifts/Edit';
import CreateForklift from './pages/Forklifts/Create';
import ShowForklift from './pages/Forklifts/Show';
import ShowForkliftLogs from './pages/Forklifts/Loggar';
import Icons_forklift from './assets/images/icons/forklift.svg';
import Icons_user from './assets/images/icons/user.svg';
import Icons_logout from './assets/images/icons/logout.svg';
import Icons_user_settings from './assets/images/icons/user-gear.svg';
import Icons_CalendarRange from './assets/images/icons/calendar-range.svg';
// Libs
import Icon from './components/Icon';
import axios from 'axios';
export var routes = [
    {
        path: "/",
        element: Forklifts,
        props: {
            onlyInStock: false
        },
        requiresAdmin: false,
        navigationConfig: {
            title: "Truckar",
            show: true,
            role: 0,
            icon: _jsx(Icon, { src: Icons_forklift, size: "24px" })
        }
    },
    // {
    // 	path: "/nya-truckar",
    // 	element: Forklifts,
    // 	props: {
    // 		onlyInStock: true
    // 	},
    //     requiresAdmin: false,
    // 	navigationConfig: {
    // 		title: "Påväg In",
    // 		show: true,
    // 		role: 0,
    // 		icon: <Icon src={Icons_HourGlass} size="24px" />
    // 	}
    // },
    {
        path: "/loggbok",
        element: LogsPage,
        requiresAdmin: false,
        navigationConfig: {
            title: "Loggbok",
            show: true,
            role: 0,
            icon: _jsx(Icon, { src: Icons_CalendarRange, size: "24px" })
        }
    },
    {
        path: "/anvandare",
        element: Users,
        requiresAdmin: true,
        navigationConfig: {
            title: "Användare",
            show: true,
            role: 1,
            icon: _jsx(Icon, { src: Icons_user, size: "24px" })
        }
    },
    {
        path: "/anvandare/skapa",
        element: CreateUser,
        requiresAdmin: true,
        navigationConfig: {
            title: "Användare",
            show: false,
            role: 1,
            icon: _jsx(Icon, { src: Icons_user, size: "24px" })
        }
    },
    {
        path: "/anvandare/:id",
        element: EditUser,
        requiresAdmin: true,
        navigationConfig: {
            title: "Användare",
            show: false,
            role: 1,
            icon: _jsx(Icon, { src: Icons_user, size: "24px" })
        }
    },
    {
        path: "/profil",
        element: SettingsPage,
        requiresAdmin: true,
        navigationConfig: {
            title: "Profil",
            show: true,
            role: 1,
            icon: _jsx(Icon, { src: Icons_user_settings, size: "24px" })
        }
    },
    {
        path: "/",
        requiresAdmin: false,
        onClick: function () {
            axios.post('/api/user/logout').then(function () {
                window.location.href = "/";
            });
        },
        element: Home,
        navigationConfig: {
            title: "Logga ut",
            show: true,
            role: 0,
            icon: _jsx(Icon, { src: Icons_logout, size: "24px" })
        }
    },
    {
        path: "/truckar/:id/edit",
        element: EditForklift,
        requiresAdmin: true,
        navigationConfig: {
            title: "Redigera Truck",
            show: false,
            role: 1,
            icon: _jsx(Icon, { src: Icons_forklift })
        }
    },
    {
        path: "/truckar/:id/",
        element: ShowForklift,
        requiresAdmin: false,
        navigationConfig: {
            title: "Visa Truck",
            show: false,
            role: 0,
            icon: _jsx(Icon, { src: Icons_forklift })
        }
    },
    {
        path: "/truckar/:id/loggar",
        element: ShowForkliftLogs,
        requiresAdmin: false,
        navigationConfig: {
            title: "Trucklogg",
            show: false,
            role: 0,
            icon: _jsx(Icon, { src: Icons_forklift })
        }
    },
    {
        path: "/truckar/skapa/",
        element: CreateForklift,
        requiresAdmin: true,
        navigationConfig: {
            title: "Lägg till truck",
            show: false,
            role: 1,
            icon: _jsx(Icon, { src: Icons_user })
        }
    }
];
export var routeAbbreviations = {
    'home': 'Home',
    'forklifts': 'Forklifts',
    'users': 'Users',
    'profile': 'Profile',
    'login': 'Login',
    'register': 'Register',
    'logout': 'Logout',
    'edit': 'Redigera Truck',
    'view': 'Visa Truck'
};
