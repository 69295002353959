var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import Input from '../../components/Input';
import Icon from '../../components/Icon';
import { allTableColumns, cabin_types, forkliftChangeActions, mast_types, status_types, tableColumns } from '../../forklifts';
import _ from 'lodash';
import { FiltersContext } from '../../store/filters/context';
import icon_search from "../../assets/images/icons/magnifying-glass.svg";
import { Accordion } from 'react-bootstrap';
import FullPageSuspenseLoader from '../../components/FullPageLoader';
export default function ShowForklift() {
    var _a = useContext(FiltersContext), filters = _a[0].filters, dispatchFilters = _a[1];
    var _b = useState(false), showAllChanges = _b[0], setShowAllChanges = _b[1];
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState([]), forklifts = _d[0], setForklifts = _d[1];
    var _e = useState([]), changes = _e[0], setChanges = _e[1];
    var _f = useState(""), search = _f[0], setSearch = _f[1];
    useEffect(function () {
        fetch("/api/forklift/").then(function (res) { return res.json(); }).then(function (data) {
            var tempChanges = data.filter(function (forklift) { return forklift.changes.length > 0; }).map(function (forklift) {
                return forklift.changes.map(function (change) {
                    return {
                        change: change,
                        forklift: {
                            id: forklift.id,
                            name: forklift.name,
                            model: forklift.model,
                            serial: forklift.serial_number,
                            image: forklift.image,
                        }
                    };
                });
            }).flat();
            // console.log("tempChanges", tempChanges);
            setChanges(tempChanges);
            setLoading(false);
        });
    }, []);
    function groupByKey(array, key) {
        return array
            .reduce(function (hash, obj) {
            var _a;
            if (obj.change[key] === undefined)
                return hash;
            return Object.assign(hash, (_a = {},
                _a[obj.change[key]] = (hash[obj.change[key]] || []).concat(obj),
                _a));
        }, {});
    }
    var getGroupedChangeCountByHash = function () {
        return groupByKey(changes.slice(0, (showAllChanges || search.length > 2) ? changes.length : 50), "change_hash");
    };
    var filterUniqueForkliftChanges = function (item, index) {
        if (item.change.old_value == null)
            return true;
        return !(item.change.old_value == item.change.new_value);
    };
    var filterUniqueForkliftChangesBySearch = function (item, index) {
        return item.forklift.serial.toLowerCase().includes(search.toLowerCase());
    };
    var formatColumnData = function (key, data) {
        switch (key) {
            case "comment": {
                return data ? data : "Ingen kommentar";
            }
            case "mast_type": {
                return mast_types[data];
            }
            case "cabin_type": {
                return cabin_types[data];
            }
            case "in_stock": {
                return data == 1 ? "Ja" : "Nej";
            }
            case "seller":
            case "buyer": {
                return data ? data : "--";
            }
            case "stand_height":
            case "lifting_height": {
                if (!(data == 0)) {
                    return parseInt(data).toFixed(2) + ' cm';
                }
                return '--';
            }
            case "status": {
                return status_types[data];
            }
            case "secondary_status": {
                return status_types[data];
            }
            default: return data;
        }
    };
    function getUniqueListBy(array, key) {
        return _.uniqBy(array, key);
    }
    var formatChangeData = function (change) {
        if ((change === null || change === void 0 ? void 0 : change.action) == "FORKLIFT_UPDATED" || (change === null || change === void 0 ? void 0 : change.action) == "FORKLIFT_IMAGE_UPDATED") {
            return "".concat(formatColumnData(change === null || change === void 0 ? void 0 : change.key, change === null || change === void 0 ? void 0 : change.old_value), " \u279D ").concat(formatColumnData(change === null || change === void 0 ? void 0 : change.key, change === null || change === void 0 ? void 0 : change.new_value));
        }
        return null;
    };
    var formatChangedKey = function (change) {
        if ((change === null || change === void 0 ? void 0 : change.action) == "FORKLIFT_UPDATED" || (change === null || change === void 0 ? void 0 : change.action) == "FORKLIFT_IMAGE_UPDATED") {
            return {
                label: "Ändrad kolumn",
                data: (change === null || change === void 0 ? void 0 : change.action) == "FORKLIFT_IMAGE_UPDATED" ? "Bild" : allTableColumns[change === null || change === void 0 ? void 0 : change.key]
            };
        }
        return {
            label: "Ändringstyp",
            data: change === null || change === void 0 ? void 0 : change.action
        };
    };
    var getBadgeColor = function (action) {
        switch (action) {
            case "FORKLIFT_UPDATED": return "success";
            case "FORKLIFT_CREATED": return "success";
            case "FORKLIFT_DELETED": return "danger";
            case "FORKLIFT_RESERVED": return "info";
            case "FORKLIFT_RESTORED": return "warning";
            case "FORKLIFT_SOLD": return "dark";
            case "FORKLIFT_IMAGE_UPDATED": return "secondary";
            default: return "primary";
        }
    };
    var searchForkliftCallback = function (_a, index, array) {
        var forklift = _a.forklift;
        var found = false;
        Object.keys(forklift).forEach(function (key) {
            var keyValue = forklift[key];
            if (keyValue && keyValue.toString().toLowerCase().includes((search).toLowerCase())) {
                found = true;
            }
        });
        return found;
    };
    if (loading) {
        return _jsx(FullPageSuspenseLoader, {});
    }
    return (_jsx("div", __assign({ id: "page-forklift" }, { children: _jsx("div", __assign({ className: "card card-image shadow" }, { children: _jsxs("div", __assign({ className: "card-body" }, { children: [_jsxs("div", __assign({ className: "d-flex flex-row" }, { children: [_jsx(Input, { settings: {
                                    id: "search",
                                    type: "text",
                                    placeholder: "Sök...",
                                    prefix: _jsx(Icon, { src: icon_search }),
                                }, value: search, onChange: function (e) { return setSearch(e.target.value); } }), _jsx("button", __assign({ type: "button", onClick: function () { return setShowAllChanges(function (prev) { return !prev; }); }, className: "btn btn-success" }, { children: showAllChanges ? "Visa endast första 50" : "Visa alla ändringar" }))] })), Object.entries(getGroupedChangeCountByHash()).filter(function (_a) {
                        var key = _a[0], value = _a[1];
                        return value.filter(filterUniqueForkliftChanges).length > 0;
                    }).sort(function (_a, _b) {
                        var k1 = _a[0], v1 = _a[1][0];
                        var k2 = _b[0], v2 = _b[1][0];
                        return new Date(v2.change.timestamp).getTime() - new Date(v1.change.timestamp).getTime();
                    })
                        .filter(function (_a) {
                        var key = _a[0], value = _a[1][0];
                        if (!(value === null || value === void 0 ? void 0 : value.forklift))
                            return true;
                        // console.log("filterValue", value);
                        // if(value[0].changes.)
                        if (value.user_changed && value.user_changed.name.toLowerCase().includes(search.toLowerCase()))
                            return true;
                        return value.forklift.serial.toLowerCase().includes(search.toLowerCase()) || value.forklift.name.toLowerCase().includes(search.toLowerCase());
                    })
                        .map(function (_a) {
                        var key = _a[0], value = _a[1];
                        // if (value?.length > 1) {
                        return (_jsx("div", __assign({ className: "d-flex flex-column mb-0 border-bottom rounded-4" }, { children: _jsxs(Accordion, { children: [_jsx(Accordion.Header, { children: _jsxs("div", __assign({ className: "d-flex flex-column justify-content-between w-100" }, { children: [_jsxs("h6", { children: ["Truck: ", value[0].forklift.name, value[0].forklift.serial == "NO_SERIAL_NUMBER" ? " (Inget Int. nr)" : " - ".concat(value[0].forklift.serial)] }), _jsxs("small", { children: [value.filter(filterUniqueForkliftChanges).length, " \u00E4ndringar av ", value[0].change.user_changed.name, " ", _jsx("b", { children: value[0].change.timestamp })] })] })) }), _jsx(Accordion.Body, { children: value.filter(filterUniqueForkliftChanges).map(function (_a) {
                                            var forklift = _a.forklift, change = _a.change;
                                            var changedKey = formatChangedKey(change);
                                            var shouldRenderChangedValueColumns = !(change.old_value == null && change.new_value == null);
                                            var isUpdateAction = (change.action === "FORKLIFT_UPDATED" || change.action === "FORKLIFT_IMAGE_UPDATED");
                                            return (_jsxs("div", __assign({ className: "grid-4 mb-0" }, { children: [_jsxs("div", __assign({ className: "user-info-group gap-0" }, { children: [_jsx("label", __assign({ className: "form-label" }, { children: "Truck" })), _jsx("div", __assign({ className: "user" }, { children: _jsx("span", __assign({ className: "info" }, { children: _jsxs("span", { children: [_jsx("b", { children: forklift.name }), " (", forklift.serial, ")"] }) })) }))] })), _jsx("div", __assign({ className: "user-info-group gap-0" }, { children: _jsx("small", __assign({ className: "info" }, { children: _jsx("span", __assign({ className: "btn btn-block d-flex justify-content-center w-100 btn-sm btn-".concat(getBadgeColor(change === null || change === void 0 ? void 0 : change.action)) }, { children: forkliftChangeActions[change === null || change === void 0 ? void 0 : change.action] })) })) })), isUpdateAction ? (_jsx(Input, { settings: {
                                                            id: change === null || change === void 0 ? void 0 : change.key,
                                                            label: changedKey.label,
                                                            placeholder: changedKey.data,
                                                            readOnly: true,
                                                        }, value: changedKey.data })) : (_jsx(_Fragment, {})), _jsx(Input, { settings: {
                                                            id: change === null || change === void 0 ? void 0 : change.key,
                                                            label: "Ändring",
                                                            placeholder: tableColumns[change === null || change === void 0 ? void 0 : change.key],
                                                            readOnly: true,
                                                        }, value: shouldRenderChangedValueColumns ? formatChangeData(change) : "Inget värde" })] })));
                                        }) })] }) }), key));
                        // }
                    }), !showAllChanges && (_jsxs("div", { children: [_jsxs("p", __assign({ className: "mb-1" }, { children: ["Visar 50 av ", changes.length, " \u00E4ndringar"] })), _jsx("button", __assign({ type: "button", onClick: function () { return setShowAllChanges(true); }, className: "btn btn-sm btn-success" }, { children: "Visa alla \u00E4ndringar" }))] }))] })) })) })));
}
