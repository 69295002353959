export var reducer = function (state, action) {
    switch (action.type) {
        case "SET_USER": {
            return { user: action.payload };
        }
    }
    return state;
};
export var initialState = {
    user: {
        id: -1,
        name: "",
        email: "",
        avatar: ""
    }
};
