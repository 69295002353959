var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import Input from '../../components/Input';
import { UserContext } from '../../store/user/context';
import { useToasts } from 'react-toast-notifications';
export default function SettingsPage() {
    var _a = useContext(UserContext), user = _a[0].user, dispatch = _a[1];
    var _b = useState(""), profilePicture = _b[0], setProfilePicture = _b[1];
    var _c = useState(false), profilePictureChanged = _c[0], setProfilePictureChanged = _c[1];
    var _d = useState({ avatar: null }), formValue = _d[0], setFormValue = _d[1];
    var _e = useState(false), formLoading = _e[0], setFormLoading = _e[1];
    var addToast = useToasts().addToast;
    useEffect(function () {
        setProfilePicture("/uploads/avatars/".concat(user.avatar));
    }, []);
    var onProfilePictureChanged = function (event) {
        var avatar = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            if (e) {
                setFormValue({ avatar: avatar });
                setProfilePicture(e.target.result);
                setProfilePictureChanged(true);
            }
        };
        reader.readAsDataURL(avatar);
    };
    var _formatValues = function (value, key) {
        if (key == "role") {
            return parseInt(value);
        }
        return value;
    };
    var handleChange = function (event) {
        var _a;
        var value = event.target.value;
        var newValue = _formatValues(value, event.target.name);
        setFormValue(__assign(__assign({}, formValue), (_a = {}, _a[event.target.name] = newValue, _a)));
    };
    var handleImageUpload = function () {
        if (formValue.avatar !== null && profilePictureChanged) {
            var fd = new FormData();
            fd.append("avatar", formValue.avatar, formValue.avatar.name);
            window.axios.post("/api/user/".concat(user.id, "/avatar"), fd)
                .then(function (response) {
                addToast(response.data, { appearance: 'success' });
            })
                .catch(function (err) {
                var _a;
                setFormLoading(false);
                if (window.axios.isAxiosError(err)) {
                    var errors = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors;
                    errors && Object.entries(errors).map(function (message, index) { return (addToast(message[1], { appearance: 'error', autoDismissTimeout: 10000 })); });
                }
            });
        }
    };
    return (_jsx("div", __assign({ id: "page-settings" }, { children: _jsx("div", __assign({ className: "card card-user shadow" }, { children: _jsx("form", __assign({ className: "form-user" }, { children: _jsxs("div", __assign({ className: "grid-1" }, { children: [_jsxs("div", __assign({ className: "avatar-container" }, { children: [_jsx("img", { className: "avatar avatar-xl shadow", src: profilePicture }), _jsxs("div", __assign({ className: "" }, { children: [_jsx("input", { id: "profile_avatar", type: "file", accept: "image/*", hidden: true, onChange: function (e) { return onProfilePictureChanged(e); } }), _jsx("label", __assign({ htmlFor: "profile_avatar", className: "btn btn-primary mb-2" }, { children: "V\u00E4lj Profilbild" })), profilePictureChanged && (_jsx("button", __assign({ className: "btn btn-info mx-auto", type: "button", onClick: function (e) {
                                                e.preventDefault();
                                                handleImageUpload();
                                            } }, { children: "Ladda upp" })))] }))] })), _jsx(Input, { settings: {
                                id: "name",
                                label: "Namn",
                                placeholder: "Namn",
                                readOnly: true,
                            }, value: user.name, onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                id: "email",
                                label: "Email",
                                placeholder: "Mejl",
                                readOnly: true,
                            }, value: user.email, onChange: function (e) { return handleChange(e); } })] })) })) })) })));
}
