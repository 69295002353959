var CabinType;
(function (CabinType) {
    CabinType[CabinType["CabinType_None"] = 0] = "CabinType_None";
    CabinType[CabinType["CabinType_Protected"] = 1] = "CabinType_Protected";
    CabinType[CabinType["CabinType_Cabin"] = 2] = "CabinType_Cabin";
})(CabinType || (CabinType = {}));
var StatusType;
(function (StatusType) {
    StatusType[StatusType["StatusType_None"] = 0] = "StatusType_None";
    StatusType[StatusType["StatusType_Sold"] = 1] = "StatusType_Sold";
    StatusType[StatusType["StatusType_Reserved"] = 2] = "StatusType_Reserved";
    StatusType[StatusType["StatusType_Financed"] = 3] = "StatusType_Financed";
})(StatusType || (StatusType = {}));
export var mast_types = {
    // Standard
    "0": "Ingen",
    "1": "Standard",
    "2": "Duplex",
    "3": "Triplex",
    // Övriga
    "4": "V",
    "5": "FW",
    "6": "FV",
    "7": "FSW",
    "8": "FSV"
};
export var status_types = {
    "0": "Tillgänglig",
    "1": "Såld",
    "2": "Reserverad",
    "3": "Finansierad",
    "4": "Uthyrd"
};
export var secondary_status_types = {
    "0": "Tillgänglig",
    "1": "Såld",
    "2": "Reserverad",
    "3": "Finansierad",
    "4": "Uthyrd"
};
export var cabin_types = {
    "0": "Ingen",
    "1": "Skydd",
    "2": "Hytt",
};
export var forkliftChangeActions = {
    "FORKLIFT_UPDATED": "Uppdaterad",
    "FORKLIFT_CREATED": "Skapad",
    "FORKLIFT_DELETED": "Borttagen",
    "FORKLIFT_RESERVED": "Reserverad",
    "FORKLIFT_RESTORED": "Återställd",
    "FORKLIFT_SOLD": "Såld",
    "FORKLIFT_IMAGE_UPDATED": "Bild uppdaterad",
};
export var logsTableColumns = {
    "action": "Ändringstyp",
    "key": "Kolumn",
    "old_value": "Före",
    "new_value": "Efter",
    "timestamp": "Datum",
    "user_id": "Gjort av",
};
export var allTableColumns = {
    "serial_number": "Int. nr",
    "name": "Benämning",
    "model": "Modell",
    "lifting_height": "Lyfthöjd",
    "stand_height": "Stativhöjd",
    "production_year": "Prod. år",
    "mast_type": "Mastsort",
    "cabin_type": "Hyttyp",
    "location": "Lagerplats",
    "status": "Status",
    "secondary_status": "Extra Status",
    "seller": "Säljare",
    "buyer": "Köpare",
    "comment": "Kommentar",
    "in_stock": "I lager",
};
export var tableColumns = {
    // "id": "#",
    "serial_number": "Int. nr",
    "name": "Benämning",
    "model": "Modell",
    "lifting_height": "Lyfthöjd",
    "stand_height": "Stativhöjd",
    "production_year": "Prod. år",
    "mast_type": "Mastsort",
    "cabin_type": "Hyttyp",
    "location": "Lagerplats",
    "status": "Status",
    "seller": "Säljare",
    "buyer": "Köpare",
};
export var excelTableColumns = {
    "serial_number": "Int. nr",
    "name": "Benämning",
    "model": "Modell",
    "lifting_height": "Lyfthöjd",
    "stand_height": "Stativhöjd",
    "production_year": "Prod. år",
    "mast_type": "Mastsort",
    "location": "Lagerplats",
    "status": "Status",
    "comment": "Kommentar"
};
export var tableColumnsMobile = {
    "serial_number": "Int. nr",
    "name": "Benämning",
    "model": "Modell",
};
export var MastType;
(function (MastType) {
    MastType[MastType["MastType_Standard"] = 0] = "MastType_Standard";
    MastType[MastType["MastType_Duplex"] = 1] = "MastType_Duplex";
    MastType[MastType["MastType_Triplex"] = 2] = "MastType_Triplex";
})(MastType || (MastType = {}));
var mastTypes = [
    "", "Duplex", "Triplex"
];
// export const forklifts: Forklift[] = [
//     {
//         id: 0,
// 		cabin_type: CabinType.CabinType_Cabin,
//         serial_number: "19-058",
//         name: "Atlet PSP165",
//         model: "PSP",
//         mast_type: 2,
//         lifting_height: 290,
//         stand_height: 200,
//         production_year: 2015,
//         location: "Hemma",
//         status: StatusType.StatusType_Sold,
//         secondary_status: StatusType.StatusType_Sold,
//         seller: "Andreas",
//         buyer: "Fverods",
//         image: "default_forklift.png",
//         created_at: "2019-05-08T12:00:00.000Z",
//         updated_at: "2019-05-08T12:00:00.000Z",
//         deleted_at: "2019-05-08T12:00:00.000Z",
//         reserved_at: "2019-05-08T12:00:00.000Z",
// 		created_by: 1,
// 		updated_by: 1,
// 		deleted_by: 1,
// 		reserved_by: 1,
// 		changes: []
// 	},
//     {
//         id: 0,
// 		cabin_type: CabinType.CabinType_Cabin,
//         serial_number: "19-058",
//         name: "Atlet PSP165",
//         model: "PSP",
//         mast_type: 1,
//         lifting_height: 290,
//         stand_height: 200,
//         production_year: 2015,
//         location: "Hemma",
//         status: StatusType.StatusType_Sold,
//         secondary_status: StatusType.StatusType_Sold,
//         seller: "Andreas",
//         buyer: "Fverods",
//         image: "default_forklift.png",
//         created_at: "2019-05-08T12:00:00.000Z",
//         updated_at: "2019-05-08T12:00:00.000Z",
//         deleted_at: "2019-05-08T12:00:00.000Z",
//         reserved_at: "2019-05-08T12:00:00.000Z",
// 		created_by: 1,
// 		updated_by: 1,
// 		deleted_by: 1,
// 		reserved_by: 1,
// 		changes: []
// 	}
// ];
