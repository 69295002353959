export var reducer = function (state, action) {
    switch (action.type) {
        case "SET_FORKLIFTS": {
            return { forklifts: action.payload };
        }
    }
    return state;
};
export var initialState = {
    forklifts: []
};
