var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { mast_types, cabin_types, status_types, secondary_status_types } from '../../../forklifts';
import Input from '../../../components/Input';
import InputRadio from '../../../components/Input/Radio';
import Icon from '../../../components/Icon';
import icon_gear from '../../../assets/images/icons/gear.svg';
import icon_save from '../../../assets/images/icons/floppy-disk.svg';
export default function CreateForklift() {
    var addToast = useToasts().addToast;
    var _a = useState(false), formLoading = _a[0], setFormLoading = _a[1];
    var _b = useState(true), isInStock = _b[0], setIsInStock = _b[1];
    var _c = useState({
        serial_number: '',
        name: '',
        model: '',
        status: 0,
        secondary_status: 0,
        cabin_type: 0,
        mast_type: 0,
        lifting_height: 0,
        stand_height: 0,
        location: 'Hemma',
        comment: 'Ingen kommentar',
        seller: '',
        buyer: '',
        production_year: 2022,
        in_stock: 0
    }), formValue = _c[0], setformValue = _c[1];
    var _formatValues = function (value, key) {
        var intKeys = ["status", "cabin_type", "mast_type", "production_year"];
        var floatKeys = ["lifting_height", "stand_height"];
        if (intKeys.includes(key)) {
            return parseInt(value);
        }
        else if (floatKeys.includes(key)) {
            return parseFloat(value);
        }
        return value;
    };
    var handleSubmit = function () {
        setFormLoading(true);
        window.axios({
            method: 'POST',
            url: '/api/forklift',
            data: __assign(__assign({}, formValue), { serial_number: formValue.serial_number === '' ? "NO_SERIAL_NUMBER" : formValue.serial_number, in_stock: +isInStock })
        })
            .then(function (response) {
            setFormLoading(false);
            var success = response === null || response === void 0 ? void 0 : response.data;
            addToast(success, { appearance: 'success' });
            // setTimeout(() => {
            // 	document.location.href = '/';
            // }, 5500)
        })
            .catch(function (err) {
            var _a;
            if (axios.isAxiosError(err)) {
                var test2 = (_a = err.response) === null || _a === void 0 ? void 0 : _a.data.errors;
                test2 && Object.entries(test2).map(function (message, index) { return (addToast(message[1], { appearance: 'error', autoDismissTimeout: 10000 })); });
                setFormLoading(false);
            }
        });
    };
    var handleChange = function (event) {
        var _a;
        var value = event.target.value;
        var newValue = _formatValues(value, event.target.name);
        setformValue(__assign(__assign({}, formValue), (_a = {}, _a[event.target.name] = newValue, _a)));
    };
    return (_jsx("div", __assign({ id: "page-forklift" }, { children: _jsx("div", __assign({ className: "card card-image shadow" }, { children: _jsxs("form", __assign({ className: "form-forklift" }, { children: [_jsxs("div", __assign({ className: "grid-3" }, { children: [_jsxs("div", __assign({ className: "grid-2" }, { children: [_jsx(Input, { settings: {
                                            id: "name",
                                            label: "Benämning",
                                            placeholder: "Benämning",
                                        }, onChange: function (e) { return handleChange(e); }, value: formValue["name"] }), _jsxs("fieldset", __assign({ className: "form-group" }, { children: [_jsx("label", __assign({ className: "form-label" }, { children: "Finns i lager" })), _jsx("button", __assign({ className: "btn btn-".concat(isInStock ? "success" : "danger", " w-25 d-flex justify-content-center"), onClick: function (e) {
                                                    e.preventDefault();
                                                    setIsInStock(function (prev) { return !prev; });
                                                } }, { children: isInStock ? 'Ja' : 'Nej' }))] }))] })), _jsx(Input, { settings: {
                                    id: "serial_number",
                                    label: "Int. nr",
                                    placeholder: "Int. nr",
                                }, onChange: function (e) { return handleChange(e); }, value: formValue["serial_number"] }), _jsx(Input, { settings: {
                                    id: "model",
                                    label: "Modell",
                                    placeholder: "Modell",
                                }, onChange: function (e) { return handleChange(e); }, value: formValue["model"] })] })), _jsxs("div", __assign({ className: "grid-3" }, { children: [_jsx(Input, { settings: {
                                    id: "comment",
                                    label: "Kommentar",
                                    type: "text",
                                    placeholder: "Ingen Kommentar"
                                }, value: formValue["comment"], onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                    id: "location",
                                    label: "Lagerplats",
                                    type: "text",
                                    placeholder: "Lagerplats"
                                }, value: formValue["location"], onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                    id: "production_year",
                                    label: "Prod. år",
                                    type: "number",
                                    placeholder: "".concat(new Date().getFullYear())
                                }, value: formValue["production_year"], onChange: function (e) { return handleChange(e); } })] })), _jsxs("div", __assign({ className: "grid-5" }, { children: [_jsx(InputRadio, { settings: {
                                    label: "Mastsort",
                                    id: "mast_type",
                                    type: "number"
                                }, labels: __spreadArray([], Object.keys(mast_types).map(function (key) { return mast_types[key]; }), true), onChange: function (e) { return handleChange(e); }, value: formValue["mast_type"] }), _jsx(InputRadio, { settings: {
                                    label: "Hyttyp",
                                    id: "cabin_type",
                                    type: "number"
                                }, labels: __spreadArray([], Object.keys(cabin_types).map(function (key) { return cabin_types[key]; }), true), onChange: function (e) { return handleChange(e); }, value: formValue["cabin_type"] }), _jsx(InputRadio, { settings: {
                                    label: "Status",
                                    id: "status",
                                    type: "number"
                                }, labels: __spreadArray([], Object.keys(status_types).map(function (key) { return status_types[key]; }), true), onChange: function (e) { return handleChange(e); }, value: formValue["status"] }), _jsx(InputRadio, { settings: {
                                    label: "Extra status",
                                    id: "secondary_status",
                                    type: "number"
                                }, labels: __spreadArray([], Object.keys(secondary_status_types).map(function (key) { return secondary_status_types[key]; }), true), onChange: function (e) { return handleChange(e); }, value: formValue["secondary_status"] }), _jsxs("div", __assign({ className: "grid-1" }, { children: [_jsx(Input, { settings: {
                                            id: "lifting_height",
                                            label: "Lyfthöjd",
                                            type: "number",
                                            placeholder: 0,
                                            suffix: "cm",
                                            min: 0,
                                            max: 1000,
                                            step: 10
                                        }, value: formValue["lifting_height"], onChange: function (e) { return handleChange(e); } }), _jsx(Input, { settings: {
                                            id: "stand_height",
                                            label: "Stativhöjd",
                                            type: "number",
                                            placeholder: "0",
                                            suffix: "cm",
                                            min: 0,
                                            max: 1000,
                                            step: 10
                                        }, value: formValue["stand_height"], onChange: function (e) { return handleChange(e); } })] }))] })), _jsx("div", __assign({ className: "table-options" }, { children: _jsx("button", __assign({ disabled: formLoading, type: "submit", className: "btn btn-primary", onClick: function (e) {
                                e.preventDefault();
                                handleSubmit();
                            } }, { children: formLoading ? (_jsxs(_Fragment, { children: [_jsx(Icon, { src: icon_gear, classes: "animate-spin" }), "Laddar..."] })) : (_jsxs(_Fragment, { children: [_jsx(Icon, { src: icon_save }), _jsx("span", { children: "Spara" })] })) })) }))] })) })) })));
}
