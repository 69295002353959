import { jsx as _jsx } from "react/jsx-runtime";
import * as ReactDOMClient from 'react-dom/client';
import Frontend from './frontend/Frontend';
import './frontend/styles/app.sass';
import lodash from 'lodash';
import axios from 'axios';
window._ = lodash;
window.axios = axios;
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'Content-Type': 'application/json'
};
var container = document.getElementById('root');
// Create a root.
var root = ReactDOMClient.createRoot(container);
// Initial render: Render an element to the root.
root.render(_jsx(Frontend, {}));
