var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var reducer = function (state, action) {
    var _a;
    switch (action.type) {
        case "SET_KEYVALUE": {
            return {
                filters: __assign(__assign({}, state.filters), (_a = {}, _a[action.payload.key] = action.payload.value, _a))
            };
        }
        case "SET_YEAR": {
            return {
                filters: __assign(__assign({}, state.filters), { year: action.payload })
            };
        }
        case "SET_SEARCH": {
            return {
                filters: __assign(__assign({}, state.filters), { search: action.payload })
            };
        }
        case "SET_SHOW_IN_STOCK_ONLY": {
            return {
                filters: __assign(__assign({}, state.filters), { in_stock_only: action.payload })
            };
        }
        case "SET_SHOW_DELETED": {
            return {
                filters: __assign(__assign({}, state.filters), { show_deleted: action.payload })
            };
        }
        case "SET_SHOW_SOLD": {
            return {
                filters: __assign(__assign({}, state.filters), { show_sold: action.payload })
            };
        }
        case "SET_SHOW_COLORED_ROWS": {
            return {
                filters: __assign(__assign({}, state.filters), { show_colored: action.payload })
            };
        }
        case "SET_FORKLIFT_DATE": {
            return {
                filters: __assign(__assign({}, state.filters), { forklift_history: action.payload })
            };
        }
        case "RESET_FILTERS": {
            return initialState;
        }
    }
    return state;
};
export var initialState = {
    filters: {
        search: "",
        year: "",
        forklift_history: "",
        in_stock_only: false,
        show_colored: true,
        show_sold: false,
        show_deleted: false
    }
};
