var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useReducer, createContext } from "react";
import { reducer, initialState } from "./reducer";
var ForkliftsContext = createContext({
    state: initialState,
    dispatch: function () { return null; }
});
var ForkliftsProvider = function (props) {
    var _a = useReducer(reducer, initialState), state = _a[0], dispatch = _a[1];
    return (_jsx(ForkliftsContext.Provider, __assign({ value: [state, dispatch] }, { children: props.children })));
};
export { ForkliftsContext, ForkliftsProvider };
