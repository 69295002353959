import { useCallback, useState } from 'react';
export default function useToggle(initialState) {
    if (initialState === void 0) { initialState = false; }
    // Initialize the state
    var _a = useState(initialState), state = _a[0], setState = _a[1];
    // Define and memorize toggler function in case we pass down the comopnent,
    // This function change the boolean value to it's opposite value
    var toggle = useCallback(function () { return setState(function (state) { return !state; }); }, []);
    return [state, toggle];
}
