var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useContext } from 'react';
import Input from '../../../components/Input';
import { allTableColumns, cabin_types, forkliftChangeActions, mast_types, status_types, tableColumns } from '../../../forklifts';
import { useParams } from 'react-router-dom';
import { FiltersContext } from '../../../store/filters/context';
import { Accordion } from 'react-bootstrap';
import FullPageSuspenseLoader from '../../../components/FullPageLoader';
export default function ShowForklift() {
    var _a = useContext(FiltersContext), filters = _a[0].filters, dispatchFilters = _a[1];
    var id = useParams().id;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useState({
        id: -1,
        cabin_type: -1,
        serial_number: "",
        name: "",
        model: "",
        mast_type: -1,
        lifting_height: -1,
        stand_height: -1,
        production_year: -1,
        location: "",
        status: -1,
        secondary_status: -1,
        seller: "",
        buyer: "",
        image: "",
        comment: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        reserved_at: "",
        created_by: -1,
        updated_by: -1,
        deleted_by: -1,
        reserved_by: -1,
        in_stock: 0,
        user_created: [],
        user_updated: [],
        user_deleted: [],
        user_reserved: [],
        changes: []
    }), forklift = _c[0], setForklift = _c[1];
    var _d = useState([]), changes = _d[0], setChanges = _d[1];
    useEffect(function () {
        fetch("/api/forklift/".concat(id)).then(function (res) { return res.json(); }).then(function (data) {
            // console.log("forklift", data);
            setForklift(data);
            // console.log("changes", data.changes)
            setLoading(false);
            var tempChanges = data.changes.map(function (change) {
                return {
                    change: change,
                    forklift: {
                        id: data.id,
                        name: data.name,
                        model: data.model,
                        serial: data.serial_number,
                        image: data.image,
                    }
                };
            }).flat();
            // console.log("tempChanges", tempChanges)
            setChanges(tempChanges);
        });
    }, []);
    var formatColumnData = function (key, data) {
        switch (key) {
            case "comment": {
                return data ? data : "Ingen kommentar";
            }
            case "mast_type": {
                return mast_types[data];
            }
            case "cabin_type": {
                return cabin_types[data];
            }
            case "in_stock": {
                return data == 1 ? "Ja" : "Nej";
            }
            case "seller":
            case "buyer": {
                return data ? data : "--";
            }
            case "stand_height":
            case "lifting_height": {
                if (!(data == 0)) {
                    return parseInt(data).toFixed(2) + ' cm';
                }
                return '--';
            }
            case "status": {
                return status_types[data];
            }
            case "secondary_status": {
                return status_types[data];
            }
            default: return data;
        }
    };
    var formatChangeData = function (change) {
        if (change.action == "FORKLIFT_UPDATED" || change.action == "FORKLIFT_IMAGE_UPDATED") {
            return "".concat(formatColumnData(change.key, change.old_value), " => ").concat(formatColumnData(change.key, change.new_value));
        }
        return null;
    };
    var formatChangedKey = function (change) {
        if (change.action == "FORKLIFT_UPDATED" || change.action == "FORKLIFT_IMAGE_UPDATED") {
            return {
                label: "Ändrad kolumn",
                data: change.action == "FORKLIFT_IMAGE_UPDATED" ? "Bild" : allTableColumns[change.key]
            };
        }
        return {
            label: "Ändringstyp",
            data: change.action
        };
    };
    var getBadgeColor = function (action) {
        switch (action) {
            case "FORKLIFT_UPDATED": return "success";
            case "FORKLIFT_CREATED": return "success";
            case "FORKLIFT_DELETED": return "danger";
            case "FORKLIFT_RESERVED": return "info";
            case "FORKLIFT_RESTORED": return "warning";
            case "FORKLIFT_SOLD": return "dark";
            case "FORKLIFT_IMAGE_UPDATED": return "secondary";
            default: return "primary";
        }
    };
    function groupByKey(array, key) {
        return array
            .reduce(function (hash, obj) {
            var _a;
            if (obj.change[key] === undefined)
                return hash;
            return Object.assign(hash, (_a = {},
                _a[obj.change[key]] = (hash[obj.change[key]] || []).concat(obj),
                _a));
        }, {});
    }
    var getGroupedChangeCountByHash = function () {
        return groupByKey(changes, "change_hash");
    };
    var filterUniqueForkliftChanges = function (item, index) {
        if (item.change.old_value == null)
            return true;
        return !(item.change.old_value == item.change.new_value);
    };
    if (loading) {
        return _jsx(FullPageSuspenseLoader, {});
    }
    return (_jsx("div", __assign({ id: "page-forklift" }, { children: _jsxs("div", __assign({ className: "card card-image shadow p-5" }, { children: [_jsx("div", { className: "card-image-container", style: { backgroundImage: "url('/uploads/forklifts/".concat(forklift.image, "')") } }), _jsxs("div", __assign({ className: "d-flex flex-column mb-0 w-100" }, { children: [_jsxs("h4", { children: ["Truck: ", forklift.name, " - ", forklift.serial_number] }), changes.length == 0 && _jsx("h5", { children: "Inga h\u00E4ndelser" }), _jsx(Accordion, __assign({ defaultActiveKey: "0" }, { children: Object.entries(getGroupedChangeCountByHash()).filter(function (_a) {
                                var key = _a[0], value = _a[1];
                                return value.filter(filterUniqueForkliftChanges).length > 0;
                            }).sort(function (_a, _b) {
                                var k1 = _a[0], v1 = _a[1][0];
                                var k2 = _b[0], v2 = _b[1][0];
                                return new Date(v2.change.timestamp).getTime() - new Date(v1.change.timestamp).getTime();
                            })
                                .map(function (_a, index) {
                                var key = _a[0], value = _a[1];
                                // if (value?.length > 1) {
                                return (_jsxs(Accordion.Item, __assign({ className: "d-flex flex-column mb-0 border-bottom", eventKey: index.toString() }, { children: [_jsx(Accordion.Header, { children: _jsx("div", __assign({ className: "d-flex flex-column justify-content-between w-100" }, { children: _jsxs("h4", { children: [_jsx("b", { children: value[0].change.timestamp }), " | ", value.filter(filterUniqueForkliftChanges).length, " \u00E4ndringar av ", value[0].change.user_changed.name] }) })) }), _jsx(Accordion.Body, { children: value.filter(filterUniqueForkliftChanges).map(function (data) {
                                                var _a, _b, _c;
                                                var changedKey = formatChangedKey(data.change);
                                                var shouldRenderChangedValueColumns = !(data.change.old_value == null && data.change.new_value == null);
                                                var isUpdateAction = (data.change.action === "FORKLIFT_UPDATED" || data.change.action === "FORKLIFT_IMAGE_UPDATED");
                                                return (_jsxs("div", __assign({ className: "grid-2 mb-0" }, { children: [isUpdateAction ? (_jsx(Input, { settings: {
                                                                id: (_a = data.change) === null || _a === void 0 ? void 0 : _a.key,
                                                                label: changedKey.label,
                                                                placeholder: changedKey.data,
                                                                readOnly: true,
                                                            }, value: changedKey.data })) : (_jsx("button", __assign({ className: "btn btn-sm ".concat(getBadgeColor(data.change.action)) }, { children: forkliftChangeActions[data.change.action] }))), _jsx(Input, { settings: {
                                                                id: (_b = data.change) === null || _b === void 0 ? void 0 : _b.key,
                                                                label: "Ändring",
                                                                placeholder: tableColumns[(_c = data.change) === null || _c === void 0 ? void 0 : _c.key],
                                                                readOnly: true,
                                                            }, value: shouldRenderChangedValueColumns ? formatChangeData(data.change) : "Inget värde" })] })));
                                            }) })] }), key));
                                // }
                            }) }))] }))] })) })));
}
