import { jsx as _jsx } from "react/jsx-runtime";
import SVG from 'react-inlinesvg';
export default function Icon(_a) {
    var src = _a.src, size = _a.size, classes = _a.classes;
    return (_jsx(SVG, { src: src, className: classes, width: size !== null && size !== void 0 ? size : '16px', height: size !== null && size !== void 0 ? size : '16px', style: {
            minWidth: size !== null && size !== void 0 ? size : '16px',
            minHeight: size !== null && size !== void 0 ? size : '16px',
            width: size !== null && size !== void 0 ? size : '16px',
            height: size !== null && size !== void 0 ? size : '16px',
        } }));
}
