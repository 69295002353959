var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon';
import icon_gear from '../../assets/images/icons/gear.svg';
function UserCard(_a) {
    var id = _a.id, name = _a.name, email = _a.email, avatar = _a.avatar;
    return (_jsx("div", __assign({ className: "card card-user shadow" }, { children: _jsxs("div", __assign({ className: "user-info-group" }, { children: [_jsxs("div", __assign({ className: "user" }, { children: [_jsx("img", { className: "avatar avatar-sm shadow-sm", src: "/uploads/avatars/".concat(avatar), alt: "avatar", onError: function (_a) {
                                var currentTarget = _a.currentTarget;
                                return currentTarget.src = "/uploads/avatars/default_avatar.png";
                            } }), _jsxs("small", __assign({ className: "info" }, { children: [_jsx("span", __assign({ className: "title" }, { children: name })), _jsx("span", __assign({ className: "sub-title" }, { children: email }))] }))] })), _jsxs(Link, __assign({ to: "/anvandare/".concat(id), className: "btn btn-sm btn-primary" }, { children: [_jsx(Icon, { src: icon_gear }), _jsx("span", { children: "Hantera" })] }))] })) })));
}
export default function Users() {
    var _a = useState([]), users = _a[0], setUsers = _a[1];
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    useEffect(function () {
        fetch('/api/user/all').then(function (res) { return res.json(); }).then(function (data) {
            setUsers(data);
            setLoading(false);
        })
            .catch(function (err) { return console.log(err); });
    }, []);
    return (_jsxs("div", __assign({ id: "page-users" }, { children: [_jsx("div", __assign({ className: "card shadow" }, { children: _jsx(Link, __assign({ to: "/anvandare/skapa/", className: "btn btn-warning" }, { children: "Skapa anv\u00E4ndare" })) })), _jsx("div", __assign({ className: "grid-4" }, { children: loading ? (_jsx("h3", { children: "Laddar..." })) : users.map(function (user, key) { return (_jsx(UserCard, { id: user.id, name: user.name, avatar: user.avatar, email: user.email }, key)); }) }))] })));
}
