var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { ToastProvider } from 'react-toast-notifications';
var Layout = React.lazy(function () { return import('./components/Layout'); });
// import Layout from './components/Layout'
import { UserProvider } from './store/user/context';
import { FiltersProvider } from './store/filters/context';
import { ForkliftsProvider } from './store/forklifts/context';
import { Suspense } from 'react';
export default function Frontend() {
    // let history = createBrowserHistory();
    // history.listen(({ location, action }) => {
    // 	console.log("History changed", {
    // 		location,
    // 		action
    // 	})
    // });
    return (
    // <HistoryRouter history={history}>
    _jsx(BrowserRouter, { children: _jsx(FiltersProvider, { children: _jsx(ToastProvider, __assign({ placement: 'bottom-right', autoDismiss: true }, { children: _jsx(UserProvider, { children: _jsx(ForkliftsProvider, { children: _jsx(Suspense, __assign({ fallback: (_jsx("span", { children: "Loading..." })) }, { children: _jsx(Layout, { children: _jsx(Routes, { children: routes.map(function (route, key) {
                                        return _jsx(Route, { path: route.path, element: _jsx(route.element, __assign({}, route.props)) }, key);
                                    }) }) }) })) }) }) })) }) })
    // </HistoryRouter>
    );
}
